import React from 'react';
import { Link } from 'react-router-dom';
import { Wrapper } from './styles';

const PrivacyInformation = props => {
  return (
    <Wrapper>
      {props.isSignUp ? (
        <span>By creating an account</span>
      ) : (
        <span>By signing in</span>
      )}
      , you agree to Shoptype&apos;s&nbsp;
      <Link target="_blank" to={'/policies/terms-of-service'}>
        Terms of service
      </Link>{' '}
      and{' '}
      <Link target="_blank" to={'/policies/awake-membership-agreement'}>
        Awake Market Membership Agreement
      </Link>
      .
    </Wrapper>
  );
};

export default PrivacyInformation;
