import React from 'react';
import Loader from '../../../atoms/loader';
import ConfirmationDialog from './dialogs/confirmationDialog';
import VendorNewConnectionCard from './cards/vendorNewConnectionCard';
import VendorMyConnectionCard from './cards/vendorMyConnectionCard';
import NetworkNewConnectionCard from './cards/networkNewConnectionCard';
import NetworkMyConnectionCard from './cards/networkMyConnectionCard';
import { isEmpty } from 'lodash';
import { API_BASE_URL, APP_BASE_URL } from '../../../../config/env';
import { withSnackbar, MESSAGE_SEVERITY } from '../../../hoc/withSnackbar';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  deleteWithResponseObject,
  postWithResponseObject,
  binaryToAscii
} from '../../../../utils';
import {
  Container,
  Message,
  Section,
  ConnectionCardWrapper,
  NotAvailableWrapper,
  SectionHeading,
  ErrorMessageContainer
} from './styles';

class Approvals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      newConnections: [],
      myConnections: [],
      dataAvailable: false,
      confirmationDialog: { open: false, title: '', description: '' },
      currentConnectionObj: {},
      currentAction: '',
      endpointPathParam: ''
    };

    // set the api endpoint path param for vendors and networks
    if (this.props.tab === 'vendors') {
      this.state.endpointPathParam = 'vendor-connection';
    } else if (this.props.tab === 'networks') {
      this.state.endpointPathParam = 'network-connection';
    }
  }

  componentDidMount() {
    this.fetchConnectionRequests();
  }

  fetchConnectionRequests = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    this.setState({ isLoading: true });

    const response = await getApiResponseObject(
      `${API_BASE_URL}/admin/${this.state.endpointPathParam}/requests`,
      headers
    );

    if (successStatusCodes.includes(response.status)) {
      let newConnections = [];
      let myConnections = [];

      if (!isEmpty(response.data)) {
        response.data.forEach(connection => {
          if (connection.status === 'pending') {
            newConnections.push(connection);
          } else if (connection.status === 'accepted') {
            myConnections.push(connection);
          }
        });
      }

      this.setState({
        newConnections,
        myConnections,
        dataAvailable: true,
        isLoading: false
      });
    } else {
      this.setState({
        dataAvailable: false,
        isLoading: false
      });
    }
  };

  acceptRequest = async () => {
    const connectionObjId = this.state.currentConnectionObj.id;
    const token = getAuthToken();
    const headers = { authorization: token };

    this.closeConfirmationDialog();
    this.setState({ isLoading: true });

    const response = await postWithResponseObject(
      `${API_BASE_URL}/admin/${this.state.endpointPathParam}/accept/${this.state.currentConnectionObj.id}`,
      {},
      headers
    );

    this.resetCurrentAction();
    this.setState({ isLoading: false });

    if (successStatusCodes.includes(response.status)) {
      try {
        const state = {
          id: connectionObjId,
          account_id: response.data.data.linked_account.linked_account_id,
          tab: this.props.tab
        };

        const url = new URL(response.data.data.slug);
        // delete and add redirectUri
        url.searchParams.delete('redirect_uri');
        url.searchParams.set(
          'redirect_uri',
          `${APP_BASE_URL}/stripe-connect/confirm`
        );
        // adding the state value to x_id and adding the encoded state string to the state param
        state.x_id = url.searchParams.get('state');
        url.searchParams.delete('state');
        const stateEncoded = binaryToAscii(state);
        url.searchParams.set('state', stateEncoded);

        // sorting search params in descending order
        url.searchParams.sort((a, b) => (a > b ? -1 : 1));

        localStorage.setItem('accountId', response.data.data.linked_account.linked_account_id);
        localStorage.setItem('tab', this.props.tab);
        localStorage.setItem('connectionId', connectionObjId);

        window.location.assign(decodeURIComponent(url.href));
      } catch (err) {
        console.error(err);
      }
    } else {
      this.props.showSnackbar({
        error: response.data,
        message: 'Failed to accept request',
        severity: MESSAGE_SEVERITY.error
      });
    }
  };

  declineRequest = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    this.closeConfirmationDialog();
    this.setState({ isLoading: true });

    const response = await deleteWithResponseObject(
      `${API_BASE_URL}/admin/${this.state.endpointPathParam}/decline/${this.state.currentConnectionObj.id}`,
      headers
    );

    this.resetCurrentAction();

    if (successStatusCodes.includes(response.status)) {
      this.fetchConnectionRequests();
      this.props.showSnackbar({
        error: response.data,
        message: 'Connection request declined',
        severity: MESSAGE_SEVERITY.success
      });
    } else {
      this.setState({ isLoading: false });
      this.props.showSnackbar({
        error: response.data,
        message: 'Failed to decline request',
        severity: MESSAGE_SEVERITY.error
      });
    }
  };

  openConfirmationDialog = (title, description) => {
    this.setState({
      confirmationDialog: { open: true, title, description }
    });
  };

  closeConfirmationDialog = () => {
    this.setState(
      {
        confirmationDialog: { ...this.state.confirmationDialog, open: false }
      },
      () => {
        setTimeout(() => {
          this.setState({
            confirmationDialog: {
              ...this.state.confirmationDialog,
              title: '',
              description: ''
            }
          });
        }, 500);
      }
    );
  };

  resetCurrentAction = () => {
    this.setState({
      currentConnectionObj: {},
      currentAction: ''
    });
  };

  onAccept = connectionObj => {
    this.setState(
      {
        currentConnectionObj: connectionObj,
        currentAction: 'accept'
      },
      () => {
        this.openConfirmationDialog(
          'Proceed to finish KYC?',
          'On clicking Confirm, you will be redirected to a different page where you have to finish the KYC for the selected Network. Do you want to continue?'
        );
      }
    );
  };

  onDecline = connectionObj => {
    this.setState(
      {
        currentConnectionObj: connectionObj,
        currentAction: 'decline'
      },
      () => {
        this.openConfirmationDialog(
          'Decline the connection request?',
          'On clicking Confirm, the selected Network request will be declined, the Network won\'t be notified about that. Do you want to continue?'
        );
      }
    );
  };

  onWithdraw = connectionObj => {
    this.setState(
      {
        currentConnectionObj: connectionObj,
        currentAction: 'withdraw'
      },
      () => {
        this.openConfirmationDialog(
          'Proceed to withdraw?',
          'On clicking Confirm, you will be redirected to Awake Money dashboard. Do you want to continue?'
        );
      }
    );
  };

  onRemove = connectionObj => {
    this.setState(
      {
        currentConnectionObj: connectionObj,
        currentAction: 'remove'
      },
      () => {
        this.openConfirmationDialog(
          'Remove Network from your connections?',
          'On clicking Confirm, the selected Network will be removed from your list of existing connectons. Do you want to continue?'
        );
      }
    );
  };

  // handler for confirm dialog action
  handleConfirmAction = () => {
    if (this.state.currentAction === 'accept') {
      this.acceptRequest();
    } else if (this.state.currentAction === 'decline') {
      this.declineRequest();
    } else if (this.state.currentAction === 'withdraw') {
      // this.withdraw();
    } else if (this.state.currentAction === 'remove') {
      // this.removeConnection();
    }
  };

  // handler for cancel dialog action
  handleCancelAction = () => {
    this.resetCurrentAction();
    this.closeConfirmationDialog();
  };

  render() {
    const {
      isLoading,
      newConnections,
      myConnections,
      dataAvailable,
      confirmationDialog,
      endpointPathParam
    } = this.state;
    const { tab } = this.props;
    return (
      <>
        {!isLoading && (
          <ConfirmationDialog
            open={confirmationDialog.open}
            title={confirmationDialog.title}
            description={confirmationDialog.description}
            onConfirm={this.handleConfirmAction}
            onClose={this.closeConfirmationDialog}
            onCancel={this.handleCancelAction}
          />
        )}
        <Container>
          {!isLoading && dataAvailable ? (
            <>
              {!isEmpty(newConnections) ? (
                <Section>
                  <SectionHeading>New Connections:</SectionHeading>
                  <ConnectionCardWrapper>
                    {newConnections.map((account, index) => {
                      if (tab === 'vendors') {
                        return (
                          <VendorNewConnectionCard
                            key={index}
                            vendor={account?.vendor_name || ''}
                            store={account?.store_name || ''}
                            onAccept={() => this.onAccept(account)}
                            onDecline={() => this.onDecline(account)}
                          />
                        );
                      } else if (tab === 'networks') {
                        return (
                          <NetworkNewConnectionCard
                            key={index}
                            network={account?.network_name || ''}
                            platform={account?.platform_url || ''}
                            onAccept={() => this.onAccept(account)}
                            onDecline={() => this.onDecline(account)}
                          />
                        );
                      }
                    })}
                  </ConnectionCardWrapper>
                </Section>
              ) : (
                <NotAvailableWrapper>
                  <SectionHeading>New Connections:</SectionHeading>
                  <ErrorMessageContainer>
                    <Message>
                      <i className="fas fa-times-circle danger" />
                      &nbsp;No new connections are available
                    </Message>
                  </ErrorMessageContainer>
                </NotAvailableWrapper>
              )}
              {!isEmpty(myConnections) ? (
                <Section>
                  <SectionHeading>My Connections:</SectionHeading>
                  <ConnectionCardWrapper>
                    {myConnections.map((account, index) => {
                      if (tab === 'vendors') {
                        return (
                          <VendorMyConnectionCard
                            key={index}
                            vendor={account?.vendor_name || ''}
                            store={account?.store_name || ''}
                            currency={account?.currency || ''}
                            earnings={account?.amount || 0}
                            onWithdraw={() => this.onWithdraw(account)}
                            onRemove={() => this.onRemove(account)}
                          />
                        );
                      } else if (tab === 'networks') {
                        return (
                          <NetworkMyConnectionCard
                            key={index}
                            network={account?.network_name || ''}
                            platform={account?.platform_url || ''}
                            currency={account?.currency || ''}
                            earnings={account?.amount || 0}
                            onWithdraw={() => this.onWithdraw(account)}
                            onRemove={() => this.onRemove(account)}
                          />
                        );
                      }
                    })}
                  </ConnectionCardWrapper>
                </Section>
              ) : (
                <NotAvailableWrapper>
                  <SectionHeading>My Connections:</SectionHeading>
                  <ErrorMessageContainer>
                    <Message>
                      <i className="fas fa-times-circle danger" />
                      &nbsp;No existing connections are available
                    </Message>
                  </ErrorMessageContainer>
                </NotAvailableWrapper>
              )}
            </>
          ) : (
            !isLoading &&
            !dataAvailable && (
              <NotAvailableWrapper>
                <ErrorMessageContainer>
                  <Message>
                    <i className="fas fa-times-circle danger" />
                    &nbsp;Failed to fetch connection requests
                  </Message>
                </ErrorMessageContainer>
              </NotAvailableWrapper>
            )
          )}
          {isLoading && (
            <Loader
              message="Loading..."
              isFlex={true}
              w={'100%'}
              isCenter={true}
              min_h={'40vh'}
            />
          )}
        </Container>
      </>
    );
  }
}

export default withSnackbar(Approvals);
