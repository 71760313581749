import React from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  VENDOR,
  COSELLER,
  NETWORK_OPERATOR,
  GLOBAL_EVENT_KEYS,
  CURRENCY_SYMBOL,
  TRANSACTION_PDF_HEADERS,
  TRANSACTION_PDF_HEADERS_CSV,
  TRANSACTION_PDF_KEYS
} from '../../../../constants';
import Button from '@material-ui/core/Button';
import DropDown from '../Components/DropDown';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../../../../config/env';
import { toaster } from '../../../atoms/toaster';
import { EventsBaseClient } from '../../../helpers/eventsBaseClient';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  exportPDF,
  convertNegativeCurrency,
  fetchTransactionSummary,
} from '../../../../utils';
import {
  Container,
  HeadingSection,
  Heading,
  SubHeading,
  ContentText,
  ContentHeading,
  DropDownContainer,
  WalletDetailsContainer,
  PanelContent
} from './styles';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Tooltip, Zoom, Tabs, Tab, Menu, MenuItem } from '@material-ui/core';
import { COSELLER_INFO } from '../../../../constants/tooltip'; //Same info for Vendor and Network Operator
import PropTypes, { object } from 'prop-types';
import shoptypeThemes from '../../../../theme';
import BackgroundImage from '../../../../assets/images/Group 111.png';
import EarningsIcon from '../../../../assets/images/Group 97.png';
import AccountIcon from '../../../../assets/images/Group 110.png';
import NetworkTable from './NetworkTable';
import { CSVLink } from 'react-csv';
import TransactionsTable from './TransactionsTable';
import * as actions from '../../../../redux/actions/uiActions';
import EmptyState from '../../../atoms/EmptyState';
import moment from 'moment';

const styles = () => ({
  root: {
    '& > *': {
      marginTop: 16,
      width: '100%',
      height: 38,
      textTransform: 'capitalize'
    }
  },
  tabs: {
    backgroundColor: shoptypeThemes.default.FLORAL_WHITE,
    position: 'sticky',
    top: -15,
    zIndex: 999,
    boxShadow: '0 3px 2px -2px rgba(0,0,0,.1)'
  },
  tab: {
    color: shoptypeThemes.default.MINE_SHAFT,
    fontSize: shoptypeThemes.default.X_LARGE,
    textTransform: 'none'
  },
  selectedTab: {
    backgroundColor: shoptypeThemes.default.RONCHI,
    height: '3px'
  },
  tabPanel: {
    paddingLeft: 0
  },
  detailsContainer: {
    display: 'flex'
  },
  imageContainer: {
    backgroundImage: `url(${BackgroundImage})`
  },
  leftPart: {
    width: '10%'
  },
  rightPart: {
    width: '90%',
    padding: '10px 15px'
  },
  accountIcon: {
    height: '60px',
    padding: '10px 0px 0px 10px'
  },
  networkContainer: {
    backgroundColor: '#fff',
    marginTop: '20px'
  },
  transactionsContainer: {
    backgroundColor: '#fff',
    marginTop: '20px'
  },
  networkHeading: {
    borderBottom: '1px solid #E1E1E1',
    padding: '20px',
    marginBottom: '0px'
  },
  publishPdfExcelBtn: {
    height: 'inherit',
    textTransform: 'none'
  },
  publishContainer: {
    height: '55px',
    marginLeft: '15px',
    border: 'solid 1px #B2B2B2',
    background: 'white',
    borderRadius: '2.5px'
  },
  dflex: {
    display: 'flex'
  },
  reportItem: {
    border: 'none',
    background: 'white',
    color: 'black',
    width: '100%',
    margin: '0px',
    fontSize: '16px',
    padding: 0,
    cursor: 'pointer',
    textAlign: 'left'
  }
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <PanelContent style={{ paddingLeft: 0 }}>{children}</PanelContent>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      currencyValue: '',
      routeUserType: '',
      isLoading: true,
      accountData: [],
      accountDataAvailable: false,
      selectedTabIndex: 0,
      linkedAccountData: [],
      transactionsData: [],
      transactionsTotalCount: 0,
      currentTransactionsPage: 0,
      currentTransactionsOffset: 0,
      accountsInfo: {
        payouts_configured: 0,
        payouts_pending: 0,
        total_accounts: 0
      },
      earningsDetails: [],
      anchorEl: null,
      transactionSummary: {
        credits: 0,
        debits: 0
      }
    };
  }

  componentDidMount = async () => {
    let params = new URLSearchParams(document.location.search);

    const token = params.get('access_token');

    if (token) {
      await this.updateUserProfileToLocalStorage();
    }

    this.setRouteUserType();
    await this.fetchDashboardData();
    this.fetchLinkedAccounts();
    this.fetchTransactions();
    this.fetchEarnings();
  };

  updateUserProfileToLocalStorage = async () => {
    let params = new URLSearchParams(document.location.search);
    let token = params.get('access_token');
    const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
      authorization: token
    });

    if (successStatusCodes.includes(profileRes.status)) {
      localStorage.setItem('userProfile', JSON.stringify(profileRes.data));
      EventsBaseClient.emit(GLOBAL_EVENT_KEYS.USER_PROFILE_DATA_CHANGE_EVENT);
    }
    return profileRes.data || {};
  };

  setRouteUserType = () => {
    const pathName = this.props.location.pathname;

    switch (pathName) {
      case '/wallet':
        localStorage.setItem('USER_TYPE', VENDOR);
        this.setState({ routeUserType: VENDOR });
        break;
      case '/cosellerwallet':
        localStorage.setItem('USER_TYPE', COSELLER);
        this.setState({ routeUserType: COSELLER });
        break;
      case '/networkoperator/wallet':
        localStorage.setItem('USER_TYPE', NETWORK_OPERATOR);
        this.setState({ routeUserType: NETWORK_OPERATOR });
        break;
      default:
        break;
    }
  };

  handleTabChange = (_, newSelectedTabIndex) => {
    this.setState({ selectedTabIndex: newSelectedTabIndex });
  };

  fetchDashboardData = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    this.props.showSpinnerBackdrop();

    const response = await getApiResponseObject(
      `${API_BASE_URL}/awake-money/accounts`,
      headers
    );

    if (successStatusCodes.includes(response.status)) {
      const accountData = response.data;
      if (!Array.isArray(accountData)) {
        this.setState({
          isLoading: false
        });

        return toaster('Unable fetch account information');
      }
      const preSelectedCurrecy = localStorage.getItem(
        'WALLET_SELECTED_CURRENCY'
      );
      if (accountData?.length > 0) {
        const options = accountData.map(unit => {
          return unit.asset_type_unit;
        });
        const earningsDetails = accountData.reduce((acc, ele) => {
          return {
            ...acc,
            [ele.asset_type_unit]: {
              amount_for_withdrawal: ele.amount,
              amount_on_hold: ele.amount_on_hold,
              total_earnings: ele.amount + ele.amount_on_hold
            }
          };
        }, {});
        if (!preSelectedCurrecy) {
          this.updateCurrencyValue(response.data[0].asset_type_unit);
        } else {
          this.updateCurrencyValue(preSelectedCurrecy);
          localStorage.removeItem('WALLET_SELECTED_CURRENCY');
        }

        this.setState({
          options,
          accountData,
          accountDataAvailable: true,
          isLoading: false,
          earningsDetails
        });

        // console.log(earningsDetails)
      } else {
        this.setState({
          accountDataAvailable: false,
          isLoading: false
        });
      }
    } else {
      this.setState({
        accountDataAvailable: false,
        isLoading: false
      });
    }
    this.props.hideSpinnerBackdrop();
  };

  fetchLinkedAccounts = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const { currencyValue } = this.state;

    this.props.showSpinnerBackdrop();

    const response = await getApiResponseObject(
      `${API_BASE_URL}/awake-money/linked-accounts?currency=${currencyValue}&linkedAccountId=`,
      headers
    );

    if (successStatusCodes.includes(response.status)) {
      let accountsInfo = {
        payouts_configured: response.data.payouts_configured,
        payouts_pending: response.data.payouts_pending,
        total_accounts: response.data.total_accounts
      };
      this.setState({
        linkedAccountData: response.data.linked_accounts,
        accountsInfo
      });
    } else {
      this.setState({ linkedAccountData: [] });
    }
    this.props.hideSpinnerBackdrop();
  };

  handleChangePage = async (event, newPage) => {
    if (this.state.fetchingOrders) return;
    const { currentTransactionsPage, currentTransactionsOffset } = this.state;
    const fetchOffset =
      newPage > currentTransactionsPage
        ? currentTransactionsOffset + 10
        : currentTransactionsOffset - 10;

    this.setState(
      {
        currentTransactionsPage: newPage,
        currentTransactionsOffset: fetchOffset
      },
      () => {
        this.fetchTransactions();
      }
    );
  };

  fetchTransactions = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const { currencyValue, currentTransactionsOffset } = this.state;
    const fetchParams = {
      offset: currentTransactionsOffset,
      count: 10
    };

    const response = await getApiResponseObject(
      `${API_BASE_URL}/awake-money/transactions?currency=${currencyValue}`,
      headers,
      fetchParams
    );
    if (successStatusCodes.includes(response.status)) {
      this.setState({
        transactionsData: response.data.transactions,
        transactionsTotalCount: response.data.count
      });
    } else {
      this.setState({ transactionsData: [], transactionsTotalCount: 0 });
    }
  };


  fetchEarnings = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const { currencyValue } = this.state;

    const result = await fetchTransactionSummary(
      `${API_BASE_URL}/awake-money/transactions`,
      headers,
      currencyValue
    );

    this.setState({
      transactionSummary: {
        credits: result.credits,
        debits: result.debits
      }
    });
  };


  updateCurrencyValue = currencyValue => {
    this.setState({ currencyValue });
  };

  handleChange = e => {
    this.setState({ currencyValue: e.target.value }, () => {
      this.fetchLinkedAccounts();
      this.fetchTransactions();
      this.fetchEarnings();
    });
  };
  updateLinkedData = async () => {
    localStorage.setItem('WALLET_SELECTED_CURRENCY', this.state.currencyValue);

    await this.fetchDashboardData();
    this.fetchLinkedAccounts();
    this.fetchTransactions();
  };
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  getFileName = () => {
    const { transactionsData } = this.state;
    return transactionsData?.length > 0
      ? `Transaction_Report_${moment(
        transactionsData[transactionsData.length - 1].created_at
      ).format('DD[/]MMMM[/]YYYY')}_to_${moment(
        transactionsData[0].created_at
      ).format('DD[/]MMMM[/]YYYY')}`
      : 'Transaction_Report';
  };
  render() {
    const {
      options,
      currencyValue,
      routeUserType,
      isLoading,
      accountData,
      accountDataAvailable,
      selectedTabIndex,
      linkedAccountData,
      accountsInfo,
      earningsDetails,
      anchorEl,
      transactionsData,
      transactionsTotalCount,
      currentTransactionsPage,
      transactionSummary
    } = this.state;
    const { classes } = this.props;

    return (
      <Container>
        <HeadingSection>
          <Heading>Wallet</Heading>
          <Tooltip
            TransitionComponent={Zoom}
            title={COSELLER_INFO.pageDescription.Wallet}
            arrow
          >
            <InfoRoundedIcon />
          </Tooltip>
        </HeadingSection>
        {!isLoading && accountDataAvailable && !isEmpty(accountData) ? (
          <>
            <Tabs
              className={classes.tabs}
              value={selectedTabIndex}
              TabIndicatorProps={{ className: classes.selectedTab }}
              onChange={this.handleTabChange}
            >
              <Tab className={classes.tab} label="My Wallet" />
              <Tab className={classes.tab} label="My Transactions" />
            </Tabs>

            <TabPanel value={selectedTabIndex} index={0}>
              <>
                <DropDownContainer>
                  <DropDown
                    options={options}
                    value={currencyValue}
                    handleChange={this.handleChange}
                  />
                </DropDownContainer>
                <div className={classes.detailsContainer}>
                  <WalletDetailsContainer className={classes.imageContainer}>
                    <div className={classes.detailsContainer}>
                      <div className={classes.leftPart}>
                        <img
                          className={classes.accountIcon}
                          src={AccountIcon}
                          alt="account-icon"
                        />
                      </div>
                      <div className={classes.rightPart}>
                        <SubHeading>
                          {' '}
                          No of Accounts:
                          <ContentHeading>
                            {String(accountsInfo.total_accounts).padStart(
                              2,
                              '0'
                            )}
                          </ContentHeading>{' '}
                        </SubHeading>
                        <hr />
                        <ContentText>
                          Payouts Configured:{' '}
                          {String(accountsInfo.payouts_configured).padStart(
                            2,
                            '0'
                          )}{' '}
                        </ContentText>
                        <ContentText>
                          Payouts Pending:{' '}
                          {String(accountsInfo.payouts_pending).padStart(
                            2,
                            '0'
                          )}{' '}
                        </ContentText>
                      </div>
                    </div>
                  </WalletDetailsContainer>
                  <WalletDetailsContainer className={classes.imageContainer}>
                    <div className={classes.detailsContainer}>
                      <div className={classes.leftPart}>
                        <img
                          className={classes.accountIcon}
                          src={EarningsIcon}
                          alt="account-icon"
                        />
                      </div>
                      <div className={classes.rightPart}>
                        <SubHeading>
                          {' '}
                          Total Earnings:
                          <ContentHeading>
                            {/* {convertNegativeCurrency(
                              earningsDetails[
                                currencyValue
                              ].total_earnings.toFixed(2),
                              CURRENCY_SYMBOL[currencyValue.toUpperCase()]
                            )} */

                              CURRENCY_SYMBOL[currencyValue.toUpperCase()] + transactionSummary.credits.toFixed(2)
                            }


                          </ContentHeading>{' '}
                        </SubHeading>

                        <hr />
                        <ContentText>
                          Pending for Settlement:{' '}
                          {
                            // convertNegativeCurrency(
                            //   earningsDetails[
                            //     currencyValue
                            //   ].amount_on_hold.toFixed(2),
                            //   CURRENCY_SYMBOL[currencyValue.toUpperCase()]
                            // )
                            CURRENCY_SYMBOL[currencyValue.toUpperCase()] + transactionSummary.debits.toFixed(2)
                          }
                        </ContentText>
                        {/* <ContentText>
                          Available for Withdrawal:{' '}
                          {convertNegativeCurrency(
                            earningsDetails[
                              currencyValue
                            ].amount_for_withdrawal.toFixed(2),
                            CURRENCY_SYMBOL[currencyValue.toUpperCase()]
                          )}
                        </ContentText> */}
                      </div>
                    </div>
                  </WalletDetailsContainer>
                </div>
                <div className={classes.networkContainer}>
                  <SubHeading className={classes.networkHeading}>
                    {' '}
                    Network{' '}
                  </SubHeading>
                  <NetworkTable
                    linkedAccountData={linkedAccountData}
                    updateLinkedData={this.updateLinkedData}
                    tab={routeUserType}
                  />
                </div>
              </>
            </TabPanel>

            <TabPanel value={selectedTabIndex} index={1}>
              <div className={classes.dflex}>
                <DropDownContainer>
                  <DropDown
                    options={options}
                    value={currencyValue}
                    handleChange={this.handleChange}
                  />
                </DropDownContainer>
                {/* Not required in current implementaion */}

                {/* <div className={classes.publishContainer}>
                  <Button
                    id="basic-button"
                    aria-controls={anchorEl ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchorEl ? 'true' : undefined}
                    onClick={this.handleClick}
                    className={classes.publishPdfExcelBtn}
                    disabled={transactionsData.length === 0}
                  >
                    Publish as Excel/PDF
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}
                  >
                    <MenuItem onClose={this.handleClose}>
                      <button
                        className={classes.reportItem}
                        onClick={() =>
                          exportPDF(
                            this.getFileName(),
                            [TRANSACTION_PDF_HEADERS],
                            TRANSACTION_PDF_KEYS,
                            transactionsData
                          )
                        }
                      >
                        Download PDF
                      </button>
                    </MenuItem>
                    <MenuItem onClose={this.handleClose}>
                      <CSVLink
                        data={transactionsData}
                        headers={TRANSACTION_PDF_HEADERS_CSV}
                        className={classes.reportItem}
                        filename={this.getFileName()}
                      >
                        Download Excel
                      </CSVLink>
                    </MenuItem>
                  </Menu>
                </div> */}
              </div>

              <div className={classes.transactionsContainer}>
                <TransactionsTable
                  transactionsData={transactionsData}
                  transactionsTotalCount={transactionsTotalCount}
                  currentTransactionsPage={currentTransactionsPage}
                  handleChangePage={this.handleChangePage}
                />
              </div>
            </TabPanel>
          </>
        ) : (
          !isLoading && (
            <EmptyState
              errorMessage={
                <span>
                  You don't have any earnings as of now,
                  <br /> once you have earnings your wallet balance shall update
                  here.
                </span>
              }
              message={
                routeUserType === NETWORK_OPERATOR
                  ? 'Make sure you have configured your Payments & Payouts and try again'
                  : ''
              }
            />
          )
        )}
      </Container>
    );
  }
}

Dashboard.propTypes = {
  classes: object,
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(withStyles(styles)(Dashboard))
);
