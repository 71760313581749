import React from 'react';
import { useState, useEffect } from 'react';

import history from '../../../history';
import LogoImage from '../../../assets/images/simple_logo.png';
import approvalsLogo from '../../../assets/images/approvals-logo.png';
import payoutsLogo from '../../../assets/images/payouts-logo.png';
import {
  getAuthToken,
  getUserType,
  isShoptypeAdmin,
  isNoShoptypeCheckout
} from '../../../utils';
import {
  SidebarContainer,
  Logo,
  Category,
  LogoContainer,
  CategoryItem,
  LogoTitle,
  CategoryItemContent,
  CategoryItemName,
  InactiveIcon,
  ActiveIcon
} from './styles';
import {
  CONSTANT_CATEGORIES,
  VENDOR_CATEGORIES,
  COSELLER_CATEGORIES,
  NETWORK_OPERATOR_CATEGORIES
} from './constants';
import { EventsBaseClient } from '../../helpers/eventsBaseClient';
import { GLOBAL_EVENT_KEYS } from '../../../constants';

const Sidebar = props => {
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState('');
  const [locationKeys, setLocationKeys] = useState([]);

  const userType = getUserType();
  const token = getAuthToken();

  let CATEGORIES = [];
  if (userType === 'NETWORK_OPERATOR') {
    CATEGORIES = [...CONSTANT_CATEGORIES, ...NETWORK_OPERATOR_CATEGORIES];
  } else if (userType === 'VENDOR') {
    CATEGORIES = [...CONSTANT_CATEGORIES, ...VENDOR_CATEGORIES];
  } else if (userType === 'COSELLER') {
    CATEGORIES = [...CONSTANT_CATEGORIES, ...COSELLER_CATEGORIES];
  }

  useEffect(() => {
    EventsBaseClient.on(
      GLOBAL_EVENT_KEYS.UPDATE_SIDEBAR_CATEGORIES,
      updateCategory
    );

    return () => {
      EventsBaseClient.removeEventListener(
        GLOBAL_EVENT_KEYS.UPDATE_SIDEBAR_CATEGORIES,
        updateCategory
      );
    };
  }, []);

  const updateCategory = () => {
    setItems();
  };

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          setItems();
        } else {
          setLocationKeys(keys => [location.key, ...keys]);
          setItems();
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    setItems();
  }, [token]);

  const setItems = () => {
    let categories = [...CATEGORIES];

    if (userType === 'NETWORK_OPERATOR') {
      const getPathName = window.location.pathname.split('/')[2];
      setActiveCategory(getPathName);

      if (isShoptypeAdmin()) {
        let item = createAdditionalCategory('approvals', approvalsLogo);
        categories.splice(5, 0, item);
        setCategories(categories);
      } else {
        setCategories(CATEGORIES);
      }
    } else if (userType === 'VENDOR') {
      const getPathName = window.location.pathname.split('/')[1];
      setActiveCategory(getPathName);

      if (isNoShoptypeCheckout()) {
        let item = createAdditionalCategory('payouts', payoutsLogo);
        categories.push(item);
        setCategories(categories);
      } else {
        setCategories(CATEGORIES);
      }
    } else {
      let getPathName = window.location.pathname.split('/')[1];
      getPathName = getPathName.replace('coseller', '');
      setActiveCategory(getPathName);
      setCategories(CATEGORIES);
    }
  };

  const createAdditionalCategory = (name, logoImage) => {
    let item = {
      name: name,
      icon: (
        <InactiveIcon>
          <img src={logoImage} width={34} height={32} />
        </InactiveIcon>
      ),
      iconSelected: (
        <ActiveIcon>
          <img src={logoImage} width={34} height={32} />
        </ActiveIcon>
      )
    };
    return item;
  };

  const goHome = () => {
    if (token) {
      // Check userType and redirect to respective dashboard
      getRoutes(userType);
    } else {
      history.push('/sign-in');
    }
  };

  const getRoutes = userType => {
    switch (userType) {
      case 'COSELLER':
        history.push('/cosellerdashboard');
        setActiveCategory('dashboard');
        break;

      case 'VENDOR':
        history.push('/dashboard');
        setActiveCategory('dashboard');
        break;

      case 'NETWORK_OPERATOR':
        history.push('/networkoperator/dashboard');
        setActiveCategory('dashboard');
        break;

      default:
        history.push('/');
    }
  };

  const getClickedRoute = categoryItem => {
    if (userType === 'COSELLER') {
      return history.push(`/coseller${categoryItem.name}`);
    } else if (userType === 'VENDOR') {
      return history.push(`/${categoryItem.name}`);
    } else {
      return history.push(`/networkoperator/${categoryItem.name}`);
    }
  };

  return (
    <SidebarContainer>
      <Category>
        <LogoContainer>
          <Logo src={LogoImage} onClick={goHome} />
          <LogoTitle>Shoptype</LogoTitle>
        </LogoContainer>
        {categories.map((categoryItem, index) => (
          <div key={index} onClick={() => getClickedRoute(categoryItem)}>
            <CategoryItem
              isActive={categoryItem.name === activeCategory}
              onClick={() => setActiveCategory(categoryItem.name)}
            >
              <CategoryItemContent>
                {categoryItem.name === activeCategory ? (
                  <>{categoryItem.iconSelected}</>
                ) : (
                  <>{categoryItem.icon}</>
                )}
                <CategoryItemName>{categoryItem.name}</CategoryItemName>
              </CategoryItemContent>
            </CategoryItem>
          </div>
        ))}
      </Category>
    </SidebarContainer>
  );
};

export default Sidebar;
