import React, { useMemo } from 'react';
import { shape, string, number, array, node } from 'prop-types';
import styled, { css } from 'styled-components';
import { Tooltip, withStyles } from '@material-ui/core';
import moment from 'moment';

const Container = styled.div`
  padding: 12px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  width: 200px;
  margin: 4px;
  color: #ffffff;
  transition: transform 0.2s;
  cursor: pointer;
  word-break: break-word;

  &:hover {
    transform: scale(1.2);
  }

  ${props =>
    props.nodeType === 'inviter'
      ? css`
          background: linear-gradient(to top, #200122, #6f0000);
        `
      : props.nodeType === 'me'
      ? css`
          background: linear-gradient(to top, #0f2027, #203a43, #2c5364);
        `
      : css`
          background: linear-gradient(to top, #000000, #434343);
        `}
`;

const Label = styled.div`
  width: fit-content;
  margin-bottom: 4px;
  font-weight: ${props => props.theme.BOLD_FONT};
`;

const LargeLabel = styled(Label)`
  font-size: ${props => props.theme.LARGE};
`;

const MediumLabel = styled(Label)`
  font-size: ${props => props.theme.MEDIUM};
`;

const SmallLabel = styled(Label)`
  font-size: ${props => props.theme.SMALL};
`;

const CustomizedTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12
  }
}))(Tooltip);

function UserNode({ data, nodeType, platformDetailsByIds }) {
  const formatTime = useMemo(
    () => time => moment.unix(time).format('Do MMM, YYYY, hh:mm'),
    []
  );

  const userTypeLabel = useMemo(
    () => (data.userTypes?.length > 0 ? data.userTypes.join(', ') : ''),
    [data.userTypes]
  );

  return (
    <Container nodeType={nodeType}>
      <CustomizedTooltip title="User name associated with this account">
        <LargeLabel>{data.name}</LargeLabel>
      </CustomizedTooltip>
      <CustomizedTooltip title="Sum of direct and indirect invitees under this account">
        <MediumLabel>{data.inviteesCount}</MediumLabel>
      </CustomizedTooltip>
      <CustomizedTooltip title="Email id linked with this account">
        <SmallLabel>{data.email}</SmallLabel>
      </CustomizedTooltip>
      {platformDetailsByIds?.[data.platformId] && (
        <>
          {platformDetailsByIds[data.platformId].network && (
            <CustomizedTooltip title="Network name on whose platform this relation was established">
              <SmallLabel>
                {platformDetailsByIds[data.platformId].network.name}
              </SmallLabel>
            </CustomizedTooltip>
          )}
          <CustomizedTooltip title="Platform url on which this relation was established">
            <SmallLabel>{platformDetailsByIds[data.platformId].url}</SmallLabel>
          </CustomizedTooltip>
        </>
      )}
      {userTypeLabel && (
        <CustomizedTooltip title="User type(s) associated with this account">
          <SmallLabel>{userTypeLabel}</SmallLabel>
        </CustomizedTooltip>
      )}
      {data.referredAt > 0 && (
        <CustomizedTooltip title="Date and time at which invite was sent to this user">
          <SmallLabel>
            Invited&nbsp;On&nbsp;{formatTime(data.referredAt)}&nbsp;Hrs
          </SmallLabel>
        </CustomizedTooltip>
      )}
      {data.acceptedAt > 0 && (
        <CustomizedTooltip title="Date and time at which this user had accepted the invite">
          <SmallLabel>
            Accepted&nbsp;On&nbsp;{formatTime(data.acceptedAt)}&nbsp;Hrs
          </SmallLabel>
        </CustomizedTooltip>
      )}
    </Container>
  );
}

UserNode.propTypes = {
  data: shape({
    name: string,
    inviteesCount: number,
    email: string,
    userTypes: array,
    referredAt: number,
    acceptedAt: number,
    platformId: string
  }).isRequired,
  nodeType: string,
  platformDetailsByIds: shape({}).isRequired
};

CustomizedTooltip.propTypes = {
  message: string,
  children: node
};

export default UserNode;
