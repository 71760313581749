import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import Loader from '../../atoms/loader';
import { isEmpty } from 'lodash';
import { VENDOR, COSELLER, NETWORK_OPERATOR } from '../../../constants';
import { object, string } from 'prop-types';
import { API_BASE_URL } from '../../../config/env';
import UserNode from './userNode';

import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  getUserType
} from '../../../utils';

import {
  Container,
  ContentSection,
  Content,
  Heading,
  ErrorMessageContainer,
  ChartContainer,
  UserNodesContainer,
  NotAvailableMessage,
  UserNodeLabel,
  Row,
  Dropdown
} from './styles';

function NotAvailable({ message }) {
  return <NotAvailableMessage>{message}</NotAvailableMessage>;
}

const Referrals = ({ location }) => {
  const [routeUserType, setRouteUserType] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [referralsData, setReferralsData] = useState({});
  const [relationshipsData, setRelationshipsData] = useState({});
  const [platformId, setPlatformId] = useState(null);

  useEffect(() => {
    setRouteUserType(getUserType());
    fetchRelationships();
  }, []);

  const fetchRelationships = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    const res = await getApiResponseObject(
      `${API_BASE_URL}/referrals`,
      headers
    );

    if (successStatusCodes.includes(res.status)) {
      setReferralsData(res.data.data);
      setRelationshipsData(res.data.data);
      setIsLoading(false);
      setDataAvailable(true);
    } else {
      setIsLoading(false);
      setDataAvailable(false);
    }
  };

  const handleDropdownChange = useCallback(
    event => {
      const selectedPlatformId = event.target.value;

      if (selectedPlatformId === 'All') {
        setReferralsData(relationshipsData);
      } else {
        const filteredInviter = relationshipsData.inviter?.filter(
          inviter => inviter.platformId === selectedPlatformId
        );

        const filteredInvitees = relationshipsData.invitees?.filter(
          invitee => invitee.platformId === selectedPlatformId
        );

        setReferralsData({
          ...relationshipsData,
          inviter: filteredInviter,
          invitees: filteredInvitees
        });
      }

      setPlatformId(selectedPlatformId);
    },
    [relationshipsData]
  );

  const dropdownOptions = useMemo(() => {
    if (!relationshipsData?.platformDetailsByIds) return null;

    return Object.keys(relationshipsData.platformDetailsByIds).map(
      platformId => (
        <option key={platformId} value={platformId}>
          {`${relationshipsData.platformDetailsByIds[platformId].network?.name} : ${relationshipsData.platformDetailsByIds[platformId].url}`}
        </option>
      )
    );
  }, [relationshipsData]);

  return (
    <Container>
      <ContentSection>
        {!isLoading && dataAvailable && !isEmpty(referralsData) ? (
          <Content>
            <Heading>Referral Tree</Heading>

            <Dropdown value={platformId} onChange={handleDropdownChange}>
              <option key="All" value="All">
                Across all platforms
              </option>
              {dropdownOptions}
            </Dropdown>

            <ChartContainer>
              <UserNodesContainer>
                <UserNodeLabel>Inviter</UserNodeLabel>
                {referralsData?.inviter?.length > 0 ? (
                  <Row>
                    {referralsData.inviter.map((inviter, index) => (
                      <UserNode
                        key={index}
                        data={inviter}
                        nodeType="inviter"
                        platformDetailsByIds={
                          relationshipsData?.platformDetailsByIds
                        }
                      />
                    ))}
                  </Row>
                ) : (
                  <NotAvailable message="Inviter not available" />
                )}
              </UserNodesContainer>
              <UserNodesContainer>
                <UserNodeLabel>You</UserNodeLabel>
                {referralsData?.me?.email?.length > 0 ? (
                  <Row>
                    <UserNode data={referralsData.me} nodeType="me" />
                  </Row>
                ) : (
                  <NotAvailable message="Your Information is not available" />
                )}
              </UserNodesContainer>
              <UserNodesContainer>
                <UserNodeLabel>Invitees</UserNodeLabel>
                {referralsData?.invitees?.length > 0 ? (
                  <Row>
                    {referralsData.invitees.map((invitee, index) => (
                      <UserNode
                        key={index}
                        data={invitee}
                        nodeType="invitee"
                        platformDetailsByIds={
                          relationshipsData?.platformDetailsByIds
                        }
                      />
                    ))}
                  </Row>
                ) : (
                  <NotAvailable message="Invitees not available" />
                )}
              </UserNodesContainer>
            </ChartContainer>
          </Content>
        ) : (
          !isLoading &&
          !dataAvailable && (
            <ErrorMessageContainer>
              Relationship information unavailable.
            </ErrorMessageContainer>
          )
        )}
        {isLoading && (
          <Loader message="Loading..." isFlex w="100%" isCenter min_h="100vh" />
        )}
      </ContentSection>
    </Container>
  );
};

Referrals.propTypes = {
  location: object
};

NotAvailable.propTypes = {
  message: string
};

export default withRouter(Referrals);
