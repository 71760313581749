import styled, { css } from 'styled-components';

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  ${flexCenter};
`;

const ContentSection = styled.div`
  background-color: ${({ theme }) => theme.SPRING_WOOD};
  color: ${({ theme }) => theme.MINE_SHAFT};
  width: 100%;
`;

const Heading = styled.div`
  font-size: ${({ theme }) => theme.PAGE_HEADING};
  font-weight: ${({ theme }) => theme.MEDIUM_FONT};
  margin-bottom: 30px;
`;

const Content = styled.div`
  padding: 24px 48px;
`;

const ErrorMessageContainer = styled.div`
  ${flexCenter};
  padding: 24px 48px;
  text-align: center;
`;

const ChartContainer = styled.div`
  display: flex;
  height: calc(100vh - 70px);
  width: 100%;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);

  & > div:nth-of-type(3) {
    flex: 1;
  }

  ${({ theme }) => theme.TABLET`
    display: unset;

    & > div:nth-of-type(3) {
      flex: unset;
    }

    ${UserNodeLabel} {
      text-align: left;
    }

    ${UserNodesContainer} {
      padding: 8px 8px 8px 0px;
      margin: 8px 8px 8px 0px;
      box-sizing: unset;
      border-left: unset;
      border-right: unset;
    }

    ${NotAvailableMessage} {
      padding: 12px 12px 12px 0px;
      margin: 12px 12px 12px 0px;
      text-align: left;
    }
  `};
`;

const NotAvailableMessage = styled.div`
  font-size: ${({ theme }) => theme.SMALL};
  font-weight: ${({ theme }) => theme.LIGHT_FONT};
  text-align: center;
  padding: 12px;
  margin: 12px;
`;

const UserNodesContainer = styled.div`
  padding: 8px;
  margin: 8px;
  box-sizing: border-box;
  border-left: 1.8px solid #e8e8e8;
  border-right: 1.8px solid #e8e8e8;
`;

const UserNodeLabel = styled.div`
  font-size: ${({ theme }) => theme.MEDIUM};
  font-weight: ${({ theme }) => theme.BOLD_FONT};
  margin: 12px 0;
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Dropdown = styled.select`
  padding: 16px;
  margin-bottom: 10px;
  background: white;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export {
  Container,
  ContentSection,
  Content,
  Heading,
  ErrorMessageContainer,
  ChartContainer,
  NotAvailableMessage,
  UserNodesContainer,
  UserNodeLabel,
  Row,
  Dropdown
};
