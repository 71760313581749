import React, { useEffect, Fragment } from 'react';
import qs from 'qs';
import history from '../../history';
import { toaster } from '../atoms/toaster';
import { withRouter } from 'react-router-dom';
import { API_BASE_URL } from '../../config/env';
import {
  getAuthToken,
  successStatusCodes,
  asciiToBinary,
  postWithResponseObject
} from '../../utils';

const StripeConnectConfirm = () => {
  // effects
  useEffect(() => {
    // checks if the decoded state has all the required properties (id, account_id, tab, x_id)
    const isStateBase64Valid = state => {
      try {
        const decoded = asciiToBinary(state);
        return decoded.id && decoded.account_id && decoded.x_id ? true : false;
      } catch (err) {
        console.error(err);
        return false;
      }
    };

    const openApprovalsPage = state => {
      history.push('/');
    };

    // iife to handle stripe connect confirm
    (async () => {
      try {
        const state = qs.parse(history.location.search, {
          ignoreQueryPrefix: true
        }).state;

        const accountId = localStorage.getItem('accountId');
        localStorage.removeItem('accountId');
        const tab = localStorage.getItem('tab');
        localStorage.removeItem('tab');
        const id = localStorage.getItem('connectionId');
        localStorage.removeItem('connectionId');

        if (accountId !== null) {

          // const { tab } = asciiToBinary(state);
          let confirmUrl = '';

          // set the api endpoint path param for vendors and networks
          if (tab === 'vendors') {
            confirmUrl = `${API_BASE_URL}/admin/vendor-connection/confirm`;
          } else if (tab === 'networks') {
            confirmUrl = `${API_BASE_URL}/admin/network-connection/confirm`;
          } else if (!tab) {
            confirmUrl = `${API_BASE_URL}/vendor-network-connections/confirm`;
          }

          const token = getAuthToken();
          const headers = { authorization: token };
          const requestBody = {
            id,
            // code,
            account_id: parseInt(accountId)
          };

          const response = await postWithResponseObject(
            confirmUrl,
            requestBody,
            headers
          );

          const openConnectionsPage = () => {
            if (tab) {
              history.push('/networkoperator/approvals');
            } else {
              history.push('/networks');
            }
          };

          if (successStatusCodes.includes(response.status)) {
            openConnectionsPage();
            toaster('Confirmed stripe connect', 'success');
          } else {
            openApprovalsPage();
            toaster('Failed to confirm stripe connect', 'error');
          }
        } else {
          openApprovalsPage();
            toaster(
              'Failed to confirm stripe connect as "accountId" is not present in localStorage',
              'error'
            );
        }
      } catch (err) {
        console.error(err);
        openApprovalsPage();
      }
    })();

  }, []);

  return <Fragment />;
};

export default withRouter(StripeConnectConfirm);
